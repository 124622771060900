
function Testimonials() {
  return (
    <>
      <section className="testimonials-section">
        <div className="container">
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              <h4>Reviewed by People</h4>
              <p>
                Discover the positive impact we've made on the our clients by
                reading through their testimonials. Our clients have experienced
                our service and results, and they're eager to share their
                positive experiences with you.
              </p>
            </div>

            <div className="all-testimonials">
              <div className="all-testimonials__box">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                  "I have been traveling a lot for many years due to my work.
                  And I have seen many different Cab Service vendors.
                  But since I met Avinash (the Proprietor of Shivshahi Tours) which was like an year ago, my search for premium cab service has ended.
                  He has a fleet of latest models of Toyota Crysta that he maintains religiously.
                  He has invested in a team of good drivers who are extremely professional and well experienced.
                  Most importantly, Avinash himself drives and is soft spoken, humble.
                  His services really stand out in today's time.
                  I certainly recommend him for most reliable and professional services if you are looking for one.
                  He is my chosen travel partner on road whenever I have to travel for work and leisure. "
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <span>
                      <h4>Ambarish Bhalekar</h4>
                      <p>Pune</p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="all-testimonials__box box-2">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                  "Sincere and hardworking. Our foreign delegates ate also comfortable for the services they provide
                  They have Good and well mannered drivers
                  They know their duties well and we dont have to
                  remind or followup with them.   Once duty is explained, service is provided.   Bills are provided on time and also cost effective."
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <span>
                      <h4>Galina Mathew </h4>
                      <p>Mumbai</p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="all-testimonials__box box-2">
              <span className="quotes-icon">
                <i className="fa-solid fa-quote-right"></i>
              </span>
              <p>
                "It's nice experience with shivshahi tours and travels"
              </p>
              <div className="all-testimonials__box__name">
                <div className="all-testimonials__box__name__profile">
                  <span>
                    <h4>Sunil Bhosale </h4>
                    <p>Nashik</p>
                  </span>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
