import "../src/dist/styles.css";
import About from "./Pages/About";
import Home from "./Pages/Home";
import Navbar from "../src/components/Navbar";
import { Route, Routes,Navigate } from "react-router-dom";
import Models from "./Pages/Models";
import TestimonialsPage from "./Pages/TestimonialsPage";
import Contact from "./Pages/Contact";
import Terms from "./Pages/Terms";
import Privacypolicy from "./Pages/Privacypolicy";


function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="models" element={<Models />} />
        <Route path="review" element={<TestimonialsPage />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacypolicy" element={<Privacypolicy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <a
      href="https://wa.me/917741975856?text=Hello! I'm interested in renting a car from your website."
      className="whatsapp_float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-whatsapp whatsapp-icon"></i>
    </a>
    </>
  );
}

export default App;
