import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";


function About() {
  return (
    <>
      <section className="about-page">
        <HeroPages name="About" />
        <div className="container">
          <div className="about-main">
            <img
              className="about-main__img"
              src={AboutMain}
              alt="car-renting"
            />
            <div className="about-main__text">
              <h3>About Company</h3>
              <p>
              We are young dreamers with the ability to chase and realize our dreams. Having pioneered some of the concepts like “Driver Cum Owner” and many more revolutionizing the entire people's transportation industry across the globe.
             While we are a young company, we have been growing across the nation since 2012. Today Shivshahi travels is one of the largest & fastest growing people transportation companies in the country with a commitment to exceed our customers' expectations for the service and overall value proposition in people transportation.
             Shivshahi Travelsv provides integrated people transportation with the objective of providing best in class quality of service with an unmatched network spanning in PAN India. The operations are carried out with a large fleet of on-road and robust technology platforms. The objective behind the venture is to have Presence across the value chain through services in the corporate people transportation which includes Car Rental Services, Employee Transportation Services, Car Leasing, Coach rentals, Technology, and Mobility Solutions.
              </p>
              <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  <img src={Box1} alt="car-icon" />
                  <span>
                    <h4>20</h4>
                    <p>Car Types</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <PlanTrip />
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Book a car by getting in touch with us</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3>7741975856</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
