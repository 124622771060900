// import Golf6 from "../images/cars-big/golf6.jpg";
// import AudiA1 from "../images/cars-big/audia1.jpg";
// import Toyota from "../images/cars-big/toyotacamry.jpg";
// import Bmw320 from "../images/cars-big/bmw320.jpg";
// import Benz from "../images/cars-big/benz.jpg";
// import Passat from "../images/cars-big/passatcc.jpg";
import Swift from "../images/cars-big/swift.jpg"
import Cryst from "../images/cars-big/crysta.jpg"
import Ertika from "../images/cars-big/ertika.jpg"
import Fortuner  from "../images/cars-big/fortuner.jpg"
import Urbania from "../images/cars-big/urbaniur.png"
import Traveller from "../images/cars-big/traveller.jpg"
import Hycross from "../images/cars-big/hycross.png"
import SML from "../images/cars-big/sml.jpg"

export const CAR_DATA = [
  [
    {
      name: "Hycross/SUV",
      price: "37",
      img: Hycross,
      model: "Golf 6",
      mark: "Volkswagen",
      year: "2008",
      doors: "4/5",
      air: "Yes",
      transmission: "Manual",
      fuel: "Diesel",
    },
  ],
  [
    {
      name: "Innova crysta/SUV",
      price: "45",
      img: Cryst,
      model: "Audi",
      mark: "A1",
      year: "2012",
      doors: "4/5",
      air: "Yes",
      transmission: "Manual",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "Swift Dzire/Sedan",
      price: "30",
      img: Swift,
      model: "Camry",
      mark: "Toyota",
      year: "2006",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Hybrid",
    },
  ],
  [
    {
      name: "Ertiga/Muv",
      price: "35",
      img: Ertika,
      model: "320",
      mark: "BMW",
      year: "2012",
      doors: "4/5",
      air: "Yes",
      transmission: "Manual",
      fuel: "Diesel",
    },
  ],
  [
    {
      name: "Tempo traveller/Ac/Non Ac /17,20 Seater",
      price: "50",
      img: Traveller,
      model: "Benz GLK",
      mark: "Mercedes",
      year: "2006",
      doors: "4/5",
      air: "Yes",
      transmission: "Manual",
      fuel: "Diesel",
    },
  ],
  [
    {
      name: "Urbania luxury 17 seater",
      price: "25",
      img: Urbania,
      model: "Passat CC",
      mark: "Volkswagen",
      year: "2008",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "Fortuner/Suv",
      price: "25",
      img: Fortuner,
      model: "Passat CC",
      mark: "Volkswagen",
      year: "2008",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    }
  ],
  [
    {
      name: "SML",
      price: "25",
      img: SML,
      model: "Passat CC",
      mark: "Volkswagen",
      year: "2008",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    }
  ]
];
