import React from 'react';
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";

const Privacypolicy = () => {
  return (
    <section className="privacypolicy-page">
      <HeroPages name="Privacypolicy" />
      <div className="container">
        <div className="privacypolicy-content">
          <div className="privacypolicy-text">
            <h2>Privacy Policy</h2>
            <p>
               Your privacy matters to shivshahi Travels (the “Company”, “we”, <span style={{ color: '#ff4d30' }}>“shivshahi travels”</span>, or “us”).

              We are respect your privacy and recognise the need to protect the personally identifiable information (any information by which you can be identified, such as name, address, and telephone number) you share with us. We would like to assure you that we follow appropriate standards when it comes to protecting your privacy on our web sites. There are times when we may collect personal information from you such as name, physical address or telephone number. It is our intent to inform you before we do that and to tell you what we intend to do with the information. Generally, you will have the option not to provide the information, and in the future you will be able to “opt out” of certain uses of the information. If you choose not to provide the information we request, you can still visit the www.shivshahitravels.in, website , but you may be unable to access certain options, offers and services. Shivshahitravels.in will not sell, trade or disclose to third parties any information derived from the registration for, or use of, any online service (including names and addresses) without the consent of the user or customer (except as required by subpoena, search warrant, or other legal process or in the case of imminent physical harm to the user or others). You should access our privacy policy regularly to ensure you understand our current policies. If you have any query / doubts, kindly feel free to drop & E-mail at info@shivshahitravels.in, Please reference the privacy policy in your subject line. Shivshahitravels.in will attempt to respond to all reasonable concerns or inquiries within five business days of receipt.
              
              Changes to our Privacy and Terms & Conditions Policies We reserve the right to modify these policies at any time, so please review it frequently. If we make material changes to these policies, we will notify you of these changes, by email, or by means of a notice on our home page.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Privacypolicy;
