import React, { useState } from 'react';
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [modal, setModal] = useState(false);

  const sendMessage = (e) => {
    e.preventDefault();

    // Construct WhatsApp message
    const whatsappMessage = `
      Full Name: ${name}
      Email: ${email}
      Message: ${message}
    `;

    // Construct WhatsApp URL with message
    const whatsappUrl = `https://wa.me/917741975856?text=${encodeURIComponent(whatsappMessage)}`;

    // Open WhatsApp chat
    window.open(whatsappUrl, "_blank");

    // Clear form fields
    setName('');
    setEmail('');
    setMessage('');
    console.log(modal)
    // Show confirmation modal
    setModal(true);
  };

  return (
    <>
      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">
          <div className="contact-div">
            <div className="contact-div__text">
              <h2>Need additional information?</h2>
              <a href="tel:+917741975856">
              &nbsp; 7741975856
              </a>
              <a href="mailto:info@shivshahitravels.in">
                &nbsp;
                info@shivshahitravels.in
              </a>
              <p>
               &nbsp; Operational address
                1204, Sarthak belva
                Pisoli, Pune - 411048.
              </p>
            </div>
            <div className="contact-div__form">
              <form onSubmit={sendMessage}>
                <label>
                  Full Name <b>*</b>
                </label>
                <input
                  type="text"
                  placeholder='E.g: "Joe Shmoe"'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />

                <label>
                  Email <b>*</b>
                </label>
                <input
                  type="email"
                  placeholder="youremail@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <label>
                  Message <b>*</b>
                </label>
                <textarea
                  placeholder="Write Here.."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>

                <button type="submit">
              &nbsp; Send
                  Message
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Responsive map */}
        <div className="responsive-map-container">
          <iframe
            title="Google Map"

            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.805627317024!2d73.89600797425227!3d18.44713237141525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ea518808af41%3A0xbe1da08eb67f13a6!2sSarthak%20Belva!5e0!3m2!1sen!2sin!4v1714845943275!5m2!1sen!2sin" width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            className="responsive-map" ></iframe>
        </div>

        <Footer />
      </section>
    </>
  );
}

export default Contact;
