import { Link } from 'react-router-dom';


function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span style={{ color: '#ff4d30' }}>CAR</span> Rental
              </li>
              <li>
                We offers a big range of vehicles for all your driving needs. We
                have the perfect car to meet your needs.
              </li>
              <li>
                <a href="tel:7741975856">
                  <i className="fa-solid fa-phone"></i> &nbsp; 7741975856
                </a>
              </li>

              <li>
                <a
                  href="mailto: 
                  info@shivshahitravels.in"
                >
                  <i className="fa-solid fa-envelope"></i>
                  &nbsp; info@shivshahitravels.in
                </a>
              </li>
            
              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/dhiraj-dhas-a01a89190/"
                >
                  Design by Dheeraj
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li style={{ color: '#ff4d30' }}>Company</li>
              <li>
                <a href="#home">Pune</a>
              </li>
              <li>
                <Link to="/terms" onClick={scrollToTop} >Terms and Conditions</Link>
              </li>
              <li><Link to="/privacypolicy" onClick={scrollToTop}>Privacy Policy</Link></li>
              <li>
               Operational address 1204, Sarthak belva Pisoli, Pune - 411048.
              </li>
            </ul>



            <ul className="footer-content__2">
              <li style={{ color: '#ff4d30' }}>ABOUT US</li>
              <p style={{ fontSize: '12px' }}>
                We are young dreamers with the ability to chase and realize our dreams. Having pioneered some of the concepts like “Driver Cum Owner” and many more revolutionizing the entire people's transportation industry across the globe.
              </p>
              <Link onClick={scrollToTop} style={{ color: '#ccc', fontSize: '13px' }} to="/about">
                read more..
              </Link>
              <div className="clearfix"></div>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
